.btnModal{
 background: red;
  border: none;
  color: white;
  border-radius: 20px;
  padding: 3% 4%;
  font-size: .7rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  box-shadow: 1px 2px 5px #000000;

}
@media (min-width:768px) {
    .btnModal{
    padding: 3% 9%;
    font-size: 1rem;
   
  
    }
  }
  
  .btnModal:hover{
    color: #1766dc;
  }

@media (min-width:1024px){
  .btnModal{
    padding: 2.5% 7.5%;
    font-size: 1rem;
   

  }
}