:root{
    --morado: #4A5EA2;
    --white: #fff;
    --gris: #dfdfdf;
}
.background{
    background-color: var(--gris) ;
    margin: 4rem 0 4rem 0;
    padding-bottom: 3rem;
}



.container{
    width: 85%;
    margin: 0 auto;
    text-align: center;   
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1.8rem;
}




.titleSecundary{
    text-align: center;
    padding: 2rem 0 2rem 0;
    color: var(--morado);
}
@media (min-width:768px){
    .titleSecundary{

        font-size: 1.8rem;
    }    
    
}
@media screen and (min-width: 500px) {
.container {
      grid-template-columns: 1fr 1fr;
    }  
  }
  @media screen and (min-width: 992px) {
    .container {
      grid-template-columns: 1fr 1fr 1fr ;
      
    }
  }

.video{
    width: 100%;
    height: 100%; 
    transition: all 500ms ease;
}
.video:hover{
    transform: scale(1.1); 
}
@media (min-width: 768px){
    .video{
        width: 100%;
        height: 220px;  
        
    }
}


@media(min-width: 1024px){
    .video{
        width: 100%;
        height: 300px;     
       
    }
}  
