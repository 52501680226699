:root{
    --morado: #4A5EA2;
    --white: #fff;
    --gris: #dfdfdf;
}
.background{
    background-color: var(--gris) ;
    margin: 0 0 4rem 0;
    padding-bottom: 3rem;
}



.container{
    width: 85%;
    margin: 0 auto;
    text-align: center;   
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1.8rem;
}




.titleSecundary{
    text-align: center;
    padding: 2rem 0 2rem 0;
    color: var(--morado);
}
@media (min-width:768px){
    .titleSecundary{

        font-size: 1.8rem;
    }    
    
}
@media screen and (min-width: 500px) {
.container {
      grid-template-columns: 1fr 1fr;
    }  
  }
  @media screen and (min-width: 768px) {
    .container {
      grid-template-columns: 1fr 1fr 1fr ;
      
    }
  }
  @media screen and (min-width: 1080px) {
    .container {
      grid-template-columns: repeat(4, 1fr) ;
      
    }
  }


.cards{
    width: 100%;
    
    /* height: auto; */
    /* height: cover;  */
    /* background-color: white;  */
    /* vertical-align: center;   */

    align-self: center; 
    /* margin-bottom: -2.5rem; */
    /* padding-bottom: 1rem; */
    
}
@media (min-width: 768px){
    .cards{
       
        /* height: 220px;   */

         display:flex;
        justify-content: center;
        align-items: center;
        
    }
}

/*
@media(min-width: 1024px){
    .video{
        width: 100%;
        height: 300px;     
       
    }
}   */
