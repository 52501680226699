:root {
  --morado: #4A5EA2;
  --white: #fff;
  --gris: #dfdfdf;
}
  
.image{
    background-image: url('../Portada.jpg');
    background-size: cover;
    max-width: 100vw;
    height: 32vh;

    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
  
  
    
}
@media(min-width:768px){
  .image{
    height: 80vh;

  }
}

  .onda{
     /* position: absolute; */
      fill: var(--morado);
      left:0; 
      top:290px; 
      width:100%;
      height:60px;
      margin-top: -32px;
      
  }
  @media (min-width:768px){
    .onda{
      fill: var(--white);
      top:102%;
      height: 200px;
      margin-top: -200px; 
      position: relative; 
      
    }
    
  }
  
  @media (min-width:1024px){
    .onda{
      margin-top: -8rem;  
      height:200px;
  

        
    }
}
 



  
   
